@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap');

:root {
   --main-color: #ff7e7e;
   --red: #e74c3c;
   --red-light: #fd8476;
   --orange: #f39c12;
   --blue: #7066fd;
   --light-color: #888;
   --light-bg: #eee;
   --black: #2c3e50;
   --white: #fff;
   --border: .1rem solid rgba(0, 0, 0, .2);
}

* {
   font-family: 'Nunito', sans-serif;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   outline: none;
   border: none;
   text-decoration: none;
}

*::selection {
   background-color: var(--main-color);
   color: #fff;
}

html {
   font-size: 62.5%;
   overflow-x: hidden;
}

html::-webkit-scrollbar {
   width: 1rem;
   height: .5rem;
}

html::-webkit-scrollbar-track {
   background-color: transparent;
}

html::-webkit-scrollbar-thumb {
   background-color: var(--main-color);
}

body {
   background-color: var(--light-bg);
   padding-left: 30rem;
}

body.dark {
   --light-color: #aaa;
   --light-bg: #333;
   --black: #fff;
   --white: #222;
   --border: .1rem solid rgba(255, 255, 255, .2);
}

body.active {
   padding-left: 0;
}

section {
   padding: 0.5rem;
   margin: 0 auto;
   max-width: 1200px;
}

.heading {
   font-size: 2.5rem;
   color: var(--black);
   margin-bottom: 2.5rem;
   border-bottom: var(--border);
   padding-bottom: 1.5rem;
   text-transform: capitalize;
}


.inline-btn,
.inline-option-btn,
.inline-delete-btn,
.btn,
.delete-btn,
.option-btn {
   border-radius: .5rem;
   color: #fff;
   font-size: 1.8rem;
   cursor: pointer;
   text-transform: capitalize;
   padding: 1rem 3rem;
   text-align: center;
   margin-top: 1rem;
}

.btn,
.delete-btn,
.option-btn {
   display: block;
   width: 100%;
}

.inline-btn,
.inline-option-btn,
.inline-delete-btn {
   display: inline-block;
}

.btn,
.inline-btn {
   background-color: var(--main-color);
}

.option-btn,
.inline-option-btn {
   background-color: var(--red-light);
}

.delete-btn,
.inline-delete-btn {
   background-color: var(--red);
}

.inline-btn:hover,
.inline-option-btn:hover,
.inline-delete-btn:hover,
.btn:hover,
.delete-btn:hover,
.option-btn:hover {
   background-color: var(--black);
   color: var(--white);
}

.flex-btn {
   display: flex;
   gap: 1rem;
}

.header {
   position: sticky;
   top: 0;
   left: 0;
   right: 0;
   background-color: var(--white);
   z-index: 1000;
   border-bottom: var(--border);
}

.header .flex {
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: relative;
   padding: 1.5rem 2rem;
}

.header .flex .logo {
   font-size: 2.5rem;
   color: var(--black);
}

.header .flex .search-form {
   width: 50rem;
   border-radius: .5rem;
   background-color: var(--light-bg);
   padding: 1.5rem 2rem;
   display: flex;
   gap: 2rem;

}

.header .flex .search-form input {
   width: 100%;
   font-size: 1.8rem;
   color: var(--black);
   background: none;
}

.header .flex .search-form button {
   background: none;
   font-size: 2rem;
   cursor: pointer;
   color: var(--black);
}

.header .flex .search-form button:hover {
   color: var(--main-color);
}

.header .flex .icons div {
   font-size: 2rem;
   color: var(--black);
   background-color: var(--light-bg);
   border-radius: .5rem;
   height: 4.5rem;
   width: 4.5rem;
   line-height: 4.5rem;
   cursor: pointer;
   text-align: center;
   margin-left: .7rem;
}

.header .flex .icons div:hover {
   background-color: var(--black);
   color: var(--white);
}

.header .flex .profile {
   position: absolute;
   top: 120%;
   right: 2rem;
   background-color: var(--white);
   border-radius: .5rem;
   padding: 1.5rem;
   text-align: center;
   overflow: hidden;
   transform-origin: top right;
   transform: scale(0);
   transition: .2s linear;
   width: 30rem;
}

.header .flex .profile.active {
   transform: scale(1);
}

.header .flex .profile .image {
   height: 10rem;
   width: 10rem;
   border-radius: 50%;
   object-fit: contain;
   margin-bottom: 1rem;
}

.header .flex .profile .name {
   font-size: 2rem;
   color: var(--black);
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.header .flex .profile .role {
   font-size: 1.8rem;
   color: var(--light-color);
}

#search-btn {
   display: none;
}

.side-bar {
   overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.side-bar::-webkit-scrollbar {
   display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.side-bar {
   -ms-overflow-style: none;
   /* IE and Edge */
   scrollbar-width: none;
   /* Firefox */
}

.side-bar {
   position: fixed;
   top: 0;
   left: 0;
   width: 30rem;
   background-color: var(--white);
   height: 100vh;
   border-right: var(--border);
   z-index: 1200;
}

.side-bar #close-btn {
   text-align: right;
   padding: 2rem;
   display: none;
}

.side-bar #close-btn i {
   text-align: right;
   font-size: 2.5rem;
   background: var(--red);
   border-radius: .5rem;
   color: var(--white);
   cursor: pointer;
   height: 4.5rem;
   width: 4.5rem;
   line-height: 4.5rem;
   text-align: center;
}

.side-bar #close-btn i:hover {
   background-color: var(--black);
}

.side-bar .profile {
   padding: 3rem 2rem;
   text-align: center;
}

.side-bar .profile .image {
   height: 10rem;
   width: 10rem;
   border-radius: 50%;
   object-fit: contain;
   margin-bottom: 1rem;
}

.side-bar .profile .name {
   font-size: 2rem;
   color: var(--black);
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.side-bar .profile .role {
   font-size: 1.8rem;
   color: var(--light-color);
}

.side-bar .navbar .link {
   display: block;
   padding: 2rem;
   font-size: 2rem;
}

.side-bar .navbar .link i {
   margin-right: 1.5rem;
   color: var(--main-color);
   transition: .2s linear;
}

.side-bar .navbar .link span {
   color: var(--light-color);
}

.side-bar .navbar .link:hover {
   background-color: var(--light-bg);
}

.side-bar .navbar .link:hover i {
   margin-right: 2.5rem;
}

.side-bar.active {
   left: -30rem;
}

.home-grid .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, 27.5rem);
   gap: 1.5rem;
   justify-content: center;
   align-items: flex-start;
}

.home-grid .box-container .box {
   background-color: var(--white);
   border-radius: .5rem;
   padding: 2rem;
}

.home-grid .box-container .box .title {
   font-size: 2rem;
   color: var(--black);
   text-transform: capitalize;
}

.home-grid .box-container .box .flex {
   display: flex;
   flex-wrap: wrap;
   gap: 1.5rem;
   margin-top: 2rem;
}

.home-grid .box-container .flex a {
   background-color: var(--light-bg);
   border-radius: .5rem;
   padding: 1rem 1.5rem;
   font-size: 1.5rem;
}

.home-grid .box-container .flex a i {
   margin-right: 1rem;
   color: var(--black);
}

.home-grid .box-container .flex a span {
   color: var(--light-color);
}

.home-grid .box-container .flex a:hover {
   background-color: var(--black);
}

.home-grid .box-container .flex a:hover span {
   color: var(--white);
}

.home-grid .box-container .flex a:hover i {
   color: var(--white);
}

.home-grid .box-container .tutor {
   padding: 1rem 0;
   font-size: 1.8rem;
   color: var(--light-color);
   line-height: 2;
}

.home-grid .box-container .likes {
   color: var(--light-color);
   font-size: 1.7rem;
   margin-top: 1.5rem;
}

.home-grid .box-container .likes span {
   color: var(--main-color);
}

.about .row {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   gap: 1.5rem;
}

.about .row .image {
   flex: 1 1 40rem;
}

.about .row .image img {
   width: 100%;
   height: 50rem;
}

.about .row .content {
   flex: 1 1 40rem;
}

.about .row .content p {
   font-size: 1.7rem;
   line-height: 2;
   color: var(--light-color);
   padding: 1rem 0;
}

.about .row .content h3 {
   font-size: 3rem;
   color: var(--black);
   text-transform: capitalize;
}

.about .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
   gap: 1.5rem;
   justify-content: center;
   align-items: flex-start;
   margin-top: 3rem;
}

.about .box-container .box {
   border-radius: .5rem;
   background-color: var(--white);
   padding: 2rem;
   display: flex;
   align-items: center;
   gap: 2.5rem;
}

.about .box-container .box i {
   font-size: 4rem;
   color: var(--main-color);
}

.about .box-container .box h3 {
   font-size: 2.5rem;
   color: var(--black);
   margin-bottom: .3rem;
}

.about .box-container .box p {
   font-size: 1.7rem;
   color: var(--light-color);
}

.reviews .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
   gap: 1.5rem;
   justify-content: center;
   align-items: flex-start;
}

.reviews .box-container .box {
   border-radius: .5rem;
   background-color: var(--white);
   padding: 2rem;
}

.reviews .box-container .box p {
   line-height: 1.7;
   font-size: 1.7rem;
   color: var(--light-color);
}

.reviews .box-container .box .student {
   margin-top: 2rem;
   display: flex;
   align-items: center;
   gap: 1.5rem;
}

.reviews .box-container .box .student img {
   height: 5rem;
   width: 5rem;
   object-fit: cover;
   border-radius: 50%;
}

.reviews .box-container .box .student h3 {
   font-size: 2rem;
   color: var(--black);
   margin-bottom: .3rem;
}

.reviews .box-container .box .student .stars {
   font-size: 1.5rem;
   color: var(--red-light);
}

.courses .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
   gap: 1.5rem;
   justify-content: center;
   align-items: flex-start;
}

.courses .box-container .box {
   border-radius: .5rem;
   background-color: var(--white);
   padding: 0.5rem;
}

.courses .box-container .box .tutor {
   display: flex;
   align-items: center;
   gap: 1.5rem;
   margin-bottom: 2.5rem;
}

.courses .box-container .box .tutor img {
   height: 5rem;
   width: 5rem;
   border-radius: 50%;
   object-fit: cover;
}

.courses .box-container .box .tutor h3 {
   font-size: 1.8rem;
   color: var(--black);
   margin-bottom: .2rem;
}

.courses .box-container .box .tutor span {
   font-size: 1.3rem;
   color: var(--light-color);
}

.courses .box-container .box .thumb {
   position: relative;
}

.courses .box-container .box .thumb span {
   position: absolute;
   top: 1rem;
   left: 1rem;
   border-radius: .5rem;
   padding: .5rem 1.5rem;
   background-color: rgba(0, 0, 0, .3);
   color: #fff;
   font-size: 1.5rem;
}

.courses .box-container .box .thumb img {
   width: 100%;
   /* height: 20rem; */
   aspect-ratio: 1/1;
   object-fit: cover;
   border-radius: .5rem;
}

.courses .box-container .box .title {
   font-size: 2rem;
   color: var(--black);
   padding-bottom: .5rem;
   padding-top: 1rem;
}

.courses .more-btn {
   text-align: center;
   margin-top: 2rem;
}

.playlist-details .row {
   display: flex;
   align-items: flex-end;
   gap: 3rem;
   flex-wrap: wrap;
   background-color: var(--white);
   padding: 2rem;
}

.playlist-details .row .column {
   flex: 1 1 40rem;
}

.playlist-details .row .column .save-playlist {
   margin-bottom: 1.5rem;
}

.playlist-details .row .column .save-playlist button {
   border-radius: .5rem;
   background-color: var(--light-bg);
   padding: 1rem 1.5rem;
   cursor: pointer;
}

.playlist-details .row .column .save-playlist button i {
   font-size: 2rem;
   color: var(--black);
   margin-right: .8rem;
}

.playlist-details .row .column .save-playlist button span {
   font-size: 1.8rem;
   color: var(--light-color);
}

.playlist-details .row .column .save-playlist button:hover {
   background-color: var(--black);
}

.playlist-details .row .column .save-playlist button:hover i {
   color: var(--white);
}

.playlist-details .row .column .save-playlist button:hover span {
   color: var(--white);
}

.playlist-details .row .column .thumb {
   position: relative;
}

.playlist-details .row .column .thumb span {
   font-size: 1.8rem;
   color: #fff;
   background-color: rgba(0, 0, 0, .3);
   border-radius: .5rem;
   position: absolute;
   top: 1rem;
   left: 1rem;
   padding: .5rem 1.5rem;
}

.playlist-details .row .column .thumb img {
   height: 30rem;
   width: 100%;
   object-fit: cover;
   border-radius: .5rem;
}

.playlist-details .row .column .tutor {
   display: flex;
   align-items: center;
   gap: 2rem;
   margin-bottom: 2rem;
}

.playlist-details .row .column .tutor img {
   height: 7rem;
   width: 7rem;
   border-radius: 50%;
   object-fit: cover;
}

.playlist-details .row .column .tutor h3 {
   font-size: 2rem;
   color: var(--black);
   margin-bottom: .2rem;
}

.playlist-details .row .column .tutor span {
   font-size: 1.5rem;
   color: var(--light-color);
}

.playlist-details .row .column .details h3 {
   font-size: 2rem;
   color: var(--black);
   text-transform: capitalize;
}

.playlist-details .row .column .details p {
   padding: 1rem 0;
   line-height: 2;
   font-size: 1.8rem;
   color: var(--light-color);
}

.playlist-videos .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
   gap: 1.5rem;
   justify-content: center;
   align-items: flex-start;
}

.playlist-videos .box-container .box {
   background-color: var(--white);
   border-radius: .5rem;
   padding: 0.5rem;
   position: relative;
}

.playlist-videos .box-container .box i {
   position: absolute;
   top: 2rem;
   left: 2rem;
   right: 2rem;
   /* height: 20rem; */
   aspect-ratio: 1/1;
   border-radius: .5rem;
   background-color: rgba(0, 0, 0, .3);
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 5rem;
   color: #fff;
   display: none;
}

.playlist-videos .box-container .box:hover i {
   display: flex;
}

.playlist-videos .box-container .box img {
   width: 100%;
   /* height: 20rem; */
   aspect-ratio: 1/1;
   object-fit: cover;
   border-radius: .5rem;
}

.playlist-videos .box-container .box h3 {
   margin-top: 1.5rem;
   font-size: 1.8rem;
   color: var(--black);
}

.playlist-videos .box-container .box:hover h3 {
   color: var(--main-color);
}

.watch-video .video-container {
   background-color: var(--white);
   border-radius: .5rem;
   padding: 0.5rem;
}

.watch-video .video-container .video {
   position: relative;
   margin-bottom: 1.5rem;
}

.watch-video .video-container .video video,
.watch-video .video-container .video audio,
.watch-video .video-container .video img {
   border-radius: .5rem;
   width: 100%;
   aspect-ratio: 1/1;
   object-fit: cover;
   background-color: #000;
}

.watch-video .video-container .title {
   font-size: 2rem;
   color: var(--black);
}

.watch-video .video-container .info {
   display: flex;
   margin-top: 1.5rem;
   margin-bottom: 2rem;
   border-bottom: var(--border);
   padding-bottom: 1.5rem;
   gap: 2.5rem;
   align-items: center;
}

.watch-video .video-container .border-none{
   border: none;
   margin-bottom: -2rem;
}

.watch-video .video-container .tag{
   border: var(--border);
   margin-right: 1rem;
}

.watch-video .video-container .info p {
   font-size: 1.6rem;
}

.watch-video .video-container .info p span {
   color: var(--light-color);
}

.watch-video .video-container .info i {
   margin-right: 1rem;
   color: var(--main-color);
}

.watch-video .video-container .tutor {
   display: flex;
   align-items: center;
   gap: 2rem;
   margin-bottom: 1rem;
}

.watch-video .video-container .tutor img {
   border-radius: 50%;
   height: 5rem;
   width: 5rem;
   object-fit: cover;
}

.watch-video .video-container .tutor h3 {
   font-size: 2rem;
   color: var(--black);
   margin-bottom: .2rem;
}

.watch-video .video-container .tutor span {
   font-size: 1.5rem;
   color: var(--light-color);
}

.watch-video .video-container .flex {
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 1.5rem;
}

.watch-video .video-container .flex button {
   border-radius: .5rem;
   padding: 1rem 1.5rem;
   font-size: 1.8rem;
   cursor: pointer;
   background-color: var(--light-bg);
}

.watch-video .video-container .flex button i {
   margin-right: 1rem;
   color: var(--black);
}

.watch-video .video-container .flex button span {
   color: var(--light-color);
}

.watch-video .video-container .flex button:hover {
   background-color: var(--black);
}

.watch-video .video-container .flex button:hover i {
   color: var(--white);
}

.watch-video .video-container .flex button:hover span {
   color: var(--white);
}

.watch-video .video-container .description {
   line-height: 1.5;
   font-size: 1.7rem;
   color: var(--light-color);
   margin-top: 2rem;
}

.watch-video .video-container .description * {
   width: 100%;
}

.comments .add-comment {
   background-color: var(--white);
   border-radius: .5rem;
   padding: 2rem;
   margin-bottom: 3rem;
}

.comments .add-comment h3 {
   font-size: 2rem;
   color: var(--black);
   margin-bottom: 1rem;
}

.comments .add-comment textarea {
   height: 20rem;
   resize: none;
   background-color: var(--light-bg);
   border-radius: .5rem;
   border: var(--border);
   padding: 1.4rem;
   font-size: 1.8rem;
   color: var(--black);
   width: 100%;
   margin: .5rem 0;
}

.comments .box-container {
   display: grid;
   gap: 2.5rem;
   background-color: var(--white);
   padding: 2rem;
   border-radius: .5rem;
}

.comments .box-container .box .user {
   display: flex;
   align-items: center;
   gap: 1.5rem;
   margin-bottom: 2rem;
}

.comments .box-container .box .user img {
   height: 5rem;
   width: 5rem;
   border-radius: 50%;
}

.comments .box-container .box .user h3 {
   font-size: 2rem;
   color: var(--black);
   margin-bottom: .2rem;
}

.comments .box-container .box .user span {
   font-size: 1.5rem;
   color: var(--light-color);
}

.comments .box-container .box .comment-box {
   border-radius: .5rem;
   background-color: var(--light-bg);
   padding: 1rem 1.5rem;
   white-space: pre-line;
   margin: .5rem 0;
   font-size: 1.8rem;
   color: var(--black);
   line-height: 1.5;
   position: relative;
   z-index: 0;
}

.comments .box-container .box .comment-box::before {
   content: '';
   position: absolute;
   top: -1rem;
   left: 1.5rem;
   background-color: var(--light-bg);
   height: 1.2rem;
   width: 2rem;
   clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.teachers .search-tutor {
   margin-bottom: 2rem;
   border-radius: .5rem;
   background-color: var(--white);
   padding: 1.5rem 2rem;
   display: flex;
   align-items: center;
   gap: 1.5rem;
}

.teachers .search-tutor input {
   width: 100%;
   background: none;
   font-size: 1.8rem;
   color: var(--black);
}

.teachers .search-tutor button {
   font-size: 2rem;
   color: var(--black);
   cursor: pointer;
   background: none;
}

.teachers .search-tutor button:hover {
   color: var(--main-color);
}

.teachers .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
   gap: 1.5rem;
   justify-content: center;
   align-items: flex-start;
}

.teachers .box-container .box {
   background-color: var(--white);
   border-radius: .5rem;
   padding: 2rem;
}

.teachers .box-container .offer {
   text-align: center;
}

.teachers .box-container .offer h3 {
   font-size: 2.5rem;
   color: var(--black);
   text-transform: capitalize;
   padding-bottom: .5rem;
}

.teachers .box-container .offer p {
   line-height: 1.7;
   padding: .5rem 0;
   color: var(--light-color);
   font-size: 1.7rem;
}

.teachers .box-container .box .tutor {
   display: flex;
   align-items: center;
   gap: 2rem;
   margin-bottom: 1.5rem;
}

.teachers .box-container .box .tutor img {
   height: 5rem;
   width: 5rem;
   border-radius: 50%;
}

.teachers .box-container .box .tutor h3 {
   font-size: 2rem;
   color: var(--black);
   margin-bottom: .2rem;
}

.teachers .box-container .box .tutor span {
   font-size: 1.6rem;
   color: var(--light-color);
}

.teachers .box-container .box p {
   padding: .5rem 0;
   font-size: 1.7rem;
   color: var(--light-color);
}

.teachers .box-container .box p span {
   color: var(--main-color);
}

.teacher-profile .details {
   text-align: center;
   background-color: var(--white);
   border-radius: .5rem;
   padding: 2rem;
}

.teacher-profile .details .tutor img {
   height: 10rem;
   width: 10rem;
   border-radius: 50%;
   object-fit: cover;
   margin-bottom: 1rem;
}

.teacher-profile .details .tutor h3 {
   font-size: 2rem;
   color: var(--black);
}

.teacher-profile .details .tutor span {
   color: var(--light-color);
   font-size: 1.7rem;
}

.teacher-profile .details .flex {
   display: flex;
   flex-wrap: wrap;
   gap: 1.5rem;
   margin-top: 2rem;
}

.teacher-profile .details .flex p {
   flex: 1 1 20rem;
   border-radius: .5rem;
   background-color: var(--light-bg);
   padding: 1.2rem 2rem;
   font-size: 1.8rem;
   color: var(--light-color);
}

.teacher-profile .details .flex p span {
   color: var(--main-color);
}

.user-profile .info {
   background-color: var(--white);
   border-radius: .5rem;
   padding: 2rem;
}

.user-profile .info .user {
   text-align: center;
   margin-bottom: 2rem;
   padding: 1rem;
}

.user-profile .info .user img {
   height: 10rem;
   width: 10rem;
   border-radius: 50%;
   object-fit: cover;
   margin-bottom: 1rem;
}

.user-profile .info .user h3 {
   font-size: 2rem;
   color: var(--black);
}

.user-profile .info .user p {
   font-size: 1.7rem;
   color: var(--light-color);
   padding: .3rem 0;
}

.user-profile .info .box-container {
   display: flex;
   flex-wrap: wrap;
   gap: 1.5rem;
}

.user-profile .info .box-container .box {
   background-color: var(--light-bg);
   border-radius: .5rem;
   padding: 2rem;
   flex: 1 1 25rem;
}

.user-profile .info .box-container .box .flex {
   display: flex;
   align-items: center;
   gap: 2rem;
   margin-bottom: 1rem;
}

.user-profile .info .box-container .box .flex i {
   font-size: 2rem;
   color: var(--white);
   background-color: var(--black);
   text-align: center;
   border-radius: .5rem;
   height: 5rem;
   width: 5rem;
   line-height: 4.9rem;
}

.user-profile .info .box-container .box .flex span {
   font-size: 2.5rem;
   color: var(--main-color);
}

.user-profile .info .box-container .box .flex p {
   color: var(--light-color);
   font-size: 1.7rem;
}

.contact .row {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   gap: 1.5rem;
}

.contact .row .image {
   flex: 1 1 50rem;
}

.contact .row .image img {
   width: 100%;
}

.contact .row form {
   flex: 1 1 30rem;
   background-color: var(--white);
   padding: 2rem;
   text-align: center;
}

.contact .row form h3 {
   margin-bottom: 1rem;
   text-transform: capitalize;
   color: var(--black);
   font-size: 2.5rem;
}

.contact .row form .box {
   width: 100%;
   border-radius: .5rem;
   background-color: var(--light-bg);
   margin: 1rem 0;
   padding: 1.4rem;
   font-size: 1.8rem;
   color: var(--black);
}

.contact .row form textarea {
   height: 20rem;
   resize: none;
}

.contact .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
   gap: 1.5rem;
   justify-content: center;
   align-items: flex-start;
   margin-top: 3rem;
}

.contact .box-container .box {
   text-align: center;
   background-color: var(--white);
   border-radius: .5rem;
   padding: 3rem;
}

.contact .box-container .box i {
   font-size: 3rem;
   color: var(--main-color);
   margin-bottom: 1rem;
}

.contact .box-container .box h3 {
   font-size: 2rem;
   color: var(--black);
   margin: 1rem 0;
}

.contact .box-container .box a {
   display: block;
   padding-top: .5rem;
   font-size: 1.8rem;
   color: var(--light-color);
}

.contact .box-container .box a:hover {
   text-decoration: underline;
   color: var(--black);
}

.form-container {
   min-height: calc(100vh - 20rem);
   display: flex;
   align-items: center;
   justify-content: center;
}

.form-container form {
   background-color: var(--white);
   border-radius: .5rem;
   padding: 0.5rem;
   width: 50rem;
}

.form-container form h3 {
   font-size: 2.5rem;
   text-transform: capitalize;
   color: var(--black);
   text-align: center;
}

.form-container form p {
   font-size: 1.7rem;
   color: var(--light-color);
   padding-top: 1rem;
}

.form-container form p span {
   color: var(--red);
}

.form-container form .box {
   font-size: 1.8rem;
   color: var(--black);
   border-radius: .5rem;
   padding: 1.4rem;
   background-color: var(--light-bg);
   width: 100%;
   margin: 1rem 0;
}






.footer {
   background-color: var(--white);
   border-top: var(--border);
   position: sticky;
   bottom: 0;
   left: 0;
   right: 0;
   text-align: center;
   font-size: 1.5rem;
   padding: 0.5rem 1rem;
   color: var(--black);
   margin-top: 0.5rem;
   z-index: 1000;
   opacity: 0.5;
   /* padding-bottom: 9.5rem; */
}

.footer span {
   color: var(--main-color);
}

/* ------------------SELECT CATEGORY STYLE START------------------------------ */

.ReactTags__tagInputField {
   width: 100%;
}

.react-tag .ReactTags__tags {
   position: relative;
}

/* Styles for the input */
.react-tag .ReactTags__tagInput {
   width: 100%;
   border-radius: 2px;
   display: inline-block;
}

.react-tag .ReactTags__tagInput input.ReactTags__tagInputField,
.react-tag .ReactTags__tagInput input.ReactTags__tagInputField:focus {
   height: 31px;
   margin: 0;
   font-size: 12px;
   width: 100%;
   /* background: var(--light-color); */
   /* border: 1px solid var(--light-color); */
   padding: 0 4px;
}

/* Styles for selected tags */
.react-tag .ReactTags__selected span.ReactTags__tag {
   border: 1px solid var(--main-color);
   background: var(--light-bg);
   font-size: 13px;
   display: inline-block;
   padding: 6px;
   margin: 0 5px 5px 5px;
   cursor: default !important;
   border-radius: 2px;
}

.react-tag .ReactTags__selected a.ReactTags__remove {
   color: var(--main-color);
   margin-left: 5px;
   cursor: pointer;
}

/* Styles for suggestions */
.react-tag .ReactTags__suggestions {
   position: absolute;
   color: var(--main-color) !important;
   font-weight: normal !important;
   font-size: 14px !important;
}

.react-tag .ReactTags__suggestions ul {
   list-style-type: none;
   box-shadow: .05em .01em .5em var(--color-gray);
   background: var(--light-bg);
   width: 100%;
}

.react-tag .ReactTags__suggestions li {
   border-bottom: 1px solid var(--main-color);
   padding: 15px 10px;
   margin: 0;
}

.react-tag .ReactTags__suggestions li mark {
   text-decoration: underline;
   background: none;
   font-weight: 600;
}

.react-tag .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
   background: var(--light-bg);
   cursor: pointer;
}

/* ------------------SELECT CATEGORY STYLE END------------------------------ */
/* ------------------CAMERA BUTTON STYLE START------------------------------ */



/* ------------------CAMERA BUTTON STYLE END------------------------------ */

@media (max-width:1200px) {

   body {
      padding-left: 0;
   }

   .side-bar {
      left: -30rem;
      transition: .2s linear;
   }

   .side-bar #close-btn {
      display: block;
   }

   .side-bar.active {
      left: 0;
      box-shadow: 0 0 0 100vw rgba(0, 0, 0, .8);
      border-right: 0;
   }

}

@media (max-width:991px) {

   html {
      font-size: 55%;
   }

}

@media (max-width:768px) {

   #search-btn {
      display: inline-block;
   }

   .header .flex .search-form {
      position: absolute;
      top: 99%;
      left: 0;
      right: 0;
      border-top: var(--border);
      border-bottom: var(--border);
      background-color: var(--white);
      border-radius: 0;
      width: auto;
      padding: 2rem;
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      transition: .2s linear;
   }

   .header .flex .search-form.active {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
   }

}

@media (max-width:450px) {

   html {
      font-size: 50%;
   }

   .flex-btn {
      gap: 0;
      flex-flow: column;
   }

   .home-grid .box-container {
      grid-template-columns: 1fr;
   }

   .about .row .image img {
      height: 25rem;
   }

}